import React, { Suspense, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline, useTheme } from '@mui/joy';
import { Workbox } from 'workbox-window';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorBoundary as _ErrorBoundary } from '~/components/ErrorBoundary';
import { library } from '@fortawesome/fontawesome-svg-core';
import { Global } from '@emotion/react';
import { rootRoutes } from '~/routes';
import { useRoutes } from '~/utils/contexts';
import { CssVarsProvider, StyledEngineProvider } from '@mui/joy/styles';

import { fas } from '@fortawesome/pro-solid-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { fat } from '@fortawesome/pro-thin-svg-icons';
import globalStyles from './globalStyles';
import RoutesProvider from '~/components/Routes';
import AuthProvider from '~/utils/auth';
import { theme } from '~/theme/customTheme';
import Toaster from '~/components/Toaster';
import Loader from '~/components/Loader';
import { pdfjs } from 'react-pdf';
import { Helmet } from 'react-helmet';
import { useAuth } from './hooks/auth';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
library.add(fad);
library.add(fas);
// For some reason this is needed for typescript to not complain, as 'fat' is wrongly typed
// @ts-ignore
library.add(fat);

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			useErrorBoundary: (error: any) =>
				!(error?.response?.status >= 400 && error?.response?.status < 500),
			refetchOnWindowFocus: false,
			networkMode: process.env.NODE_ENV === 'development' ? 'always' : undefined,
		},
		mutations: {
			// Offline support
			networkMode: process.env.NODE_ENV === 'development' ? 'always' : undefined,
		},
	},
});

const App = () => {
	const { user } = useAuth();

	const [initialising, setInitialising] = useState(true);
	const theme = useTheme();
	const { router, routerRoutes } = useRoutes();

	// TODO: Use real precache from RDM in the future if it's an experience

	return (
		<Suspense fallback={<Loader />}>
			<Global styles={globalStyles(theme)} />
			{router}
		</Suspense>
	);
};

const Root = () => {
	return (
		<StyledEngineProvider injectFirst>
			<CssVarsProvider disableTransitionOnChange theme={theme}>
				<CssBaseline />
				<ErrorBoundary
					FallbackComponent={_ErrorBoundary}
					onReset={() => {
						// reset the state of your app so the error doesn't happen again
					}}
				>
					<BrowserRouter>
						{/* <Theme> */}
						<QueryClientProvider client={queryClient}>
							<RoutesProvider routes={rootRoutes}>
								<AuthProvider>
									<Toaster />
									<App />
								</AuthProvider>
							</RoutesProvider>
						</QueryClientProvider>
						{/*</Theme>*/}
					</BrowserRouter>
				</ErrorBoundary>
			</CssVarsProvider>
		</StyledEngineProvider>
	);
};

// @ts-ignore
createRoot(document.getElementById('root')).render(<Root />);

if ('serviceWorker' in navigator && process.env.NODE_ENV === 'production') {
	const wb = new Workbox('/sw.js');

	wb.addEventListener('waiting', (event) => {
		console.log('WAITING');

		wb.addEventListener('controlling', () => {
			// At this point, reloading will ensure that the current
			// tab is loaded under the control of the new service worker.
			// Depending on your web app, you may want to auto-save or
			// persist transient state before triggering the reload.
			window.location.reload();
		});

		wb.messageSkipWaiting();

		window.addEventListener('beforeunload', async () => {
			await wb.messageSkipWaiting();
		});
	});

	wb.register();
}
