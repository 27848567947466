import * as React from 'react';
import { forwardRef } from 'react';
import Box from '@mui/joy/Box';
import Sheet from '@mui/joy/Sheet';
// icons
import { closeSidebar } from '~/utils/common';
import { useLayout } from '~/utils/contexts';

interface ISidebarProps {
	forwardedRef: React.ForwardedRef<HTMLDivElement> | null;
}

const Sidebar: React.FC<ISidebarProps> = (props) => {
	const { hasSidebar } = useLayout();

	return (
		<>
			<Box
				sx={{
					display: hasSidebar ? undefined : 'none',
					position: 'fixed',
					zIndex: 1201,
					top: 0,
					left: 0,
					width: '100vw',
					height: '100vh',
					opacity: 'var(--SideNavigation-slideIn, 0)',
					backgroundColor: 'var(--ballen-palette-background-backdrop)',
					transition: 'opacity 0.4s',
					transform: {
						xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',
						lg: 'translateX(-100%)',
					},
					overflow: 'auto',
				}}
				onClick={() => closeSidebar()}
			/>

			<Sheet
				color="neutral"
				sx={(theme) => ({
					'--Sheet-background': `var(${
						theme.palette.mode === 'dark'
							? '--ballen-palette-neutral-800'
							: '--ballen-palette-neutral-100'
					}, #F3F1F6)`,
					backgroundColor: 'var(--Sheet-background)',
					display: hasSidebar ? 'flex' : 'none',
					position: {
						xs: 'fixed',
						lg: 'sticky',
					},
					transform: {
						xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',
						lg: 'none',
					},
					transition: 'transform 0.4s',
					zIndex: 1202,
					height: '100dvh',
					width: '225px',
					top: 0,
					p: 2,
					flexShrink: 0,
					flexDirection: 'column',
					gap: 2,
					borderRight: '1px solid',
					borderColor: 'divider',
				})}
				ref={props.forwardedRef}
			>
				{/*	Data added here from portal*/}
			</Sheet>
		</>
	);
};

export default forwardRef<HTMLDivElement, Omit<ISidebarProps, 'forwardedRef'>>((props, ref) => {
	return <Sidebar {...props} forwardedRef={ref} />;
});
