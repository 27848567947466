import Box from '@mui/joy/Box';
import * as React from 'react';
import { useRef, useState } from 'react';
import { Outlet } from 'react-router-dom';
import MobileHeader from './MobileHeader';
import Navigation from './Navigation';
import { LayoutContext, ThumbbarProvider, useRoutes } from '~/utils/contexts';
import DesktopHeader from '~/components/Layout/DesktopHeader';
import { useAuth } from '~/hooks/auth';
import Sidebar from '~/components/Layout/Sidebar';
import { Container, useColorScheme } from '@mui/joy';
import { theme } from '~/theme/customTheme';
import LowerBar from '../LowerBar';

const Layout = () => {
	const [hasSidebar, setHasSidebar] = useState(false);
	const [hasCustomTitle, setHasCustomTitle] = useState(false);
	const [hasActions, setHasActions] = useState(false);
	const [hasHeaderImage, setHasHeaderImage] = useState(false);
	const [$headerImage, $setHeaderImage] = useState<HTMLDivElement | null>(null);

	const [$title, $settitle] = useState<HTMLSpanElement | null>(null);
	const [$search, $setsearch] = useState<HTMLDivElement | null>(null);
	const [$sidebar, $setSidebar] = useState<HTMLDivElement | null>(null);

	const [$actions, $setactions] = useState<HTMLDivElement | null>(null);
	const $content = useRef(null);
	const { currentRouteItem } = useRoutes();
	const { type, header: headerOptions } = currentRouteItem ?? {};

	const contentId = 'ballen-studios-app-content';

	const header = (
		<Box position="relative">
			<Box
				width="100%"
				height="500px"
				position="absolute"
				left={0}
				bottom={-440}
				zIndex={0}
				sx={(theme) => ({
					backgroundColor: theme.vars.palette.background.body,
					svg: {
						fill: theme.vars.palette.background.level1,
					},
					zIndex: 10,
				})}
			>
				<LowerBar />
			</Box>
			<Box
				sx={(theme) => ({
					px: {
						xs: 2,
						md: type === 'list' ? 3 : 6,
					},
					pt: 8,
					pb: 6,
					backgroundColor: theme.vars.palette.background.level1,
					position: 'relative',
					mb: type === 'detailed' || hasHeaderImage ? -9 : undefined,
				})}
			>
				<DesktopHeader
					$setsearch={$setsearch}
					$settitle={$settitle}
					$setactions={$setactions}
					$setHeaderImage={$setHeaderImage}
					hasCustomTitle={hasCustomTitle}
					hasHeaderImage={hasHeaderImage}
				/>
			</Box>
		</Box>
	);

	// list view type is sticky by default unless specified
	const isSticky =
		typeof headerOptions?.isSticky === 'undefined'
			? type === 'list'
			: !!headerOptions?.isSticky;

	return (
		<LayoutContext.Provider
			value={{
				$title,
				$search,
				$sidebar,
				$actions,
				$headerImage,
				setHasHeaderImage,
				hasHeaderImage,
				hasSidebar,
				setHasSidebar,
				setHasActions,
				hasActions,
				hasCustomTitle,
				setHasCustomTitle,
				$content,
				contentId,
			}}
		>
			<Box sx={{ display: 'flex', minHeight: '100dvh' }}>
				<MobileHeader />
				<Navigation />
				<Sidebar ref={$setSidebar} />

				<Box
					sx={{
						flex: 1,
						display: 'flex',
						flexDirection: 'column',
						minWidth: 0,
						height: '100dvh',
					}}
				>
					{isSticky && header}

					<ThumbbarProvider>
						<Box
							ref={$content}
							id={contentId}
							position="relative"
							component="main"
							className="MainContent"
							sx={{
								flex: 1,
								display: 'flex',
								flexDirection: 'column',
								minWidth: 0,
								overflow: 'auto',
								height: '100%',
							}}
						>
							{!isSticky && header}

							<Box
								sx={{
									px: { xs: 2, md: 4, lg: 6 },
									pb: { xs: 2, md: 4, lg: 6 },
								}}
								zIndex={20}
							>
								<Container
									disableGutters
									maxWidth={currentRouteItem?.mainContent?.container ?? 'xxl'}
								>
									<Outlet />
								</Container>
							</Box>
						</Box>
					</ThumbbarProvider>
				</Box>
			</Box>
			{/* <Footer /> */}
		</LayoutContext.Provider>
	);
};

export default Layout;
