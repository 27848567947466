import * as React from 'react';
import GlobalStyles from '@mui/joy/GlobalStyles';
import Avatar from '@mui/joy/Avatar';
import Divider from '@mui/joy/Divider';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import Sheet from '@mui/joy/Sheet';
import { Router, Link as RouterLink } from 'react-router-dom';

// icons
import { closeSidebar, getUserAvatar, toggleSidebar } from '~/utils/common';
import { navigation } from '~/routes/root';
import {
	Box,
	Dropdown,
	IconButton,
	ListItemContent,
	ListItemDecorator,
	Menu,
	MenuButton,
	MenuItem,
	Stack,
	Tooltip,
	Typography,
	useColorScheme,
	Link,
} from '@mui/joy';
import { useLayout, useRoutes } from '~/utils/contexts';
import { useAuth } from '~/hooks/auth';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OrganisationSelector from '~/components/OrganisationSelector';

const Navigation: React.FC = () => {
	const { hasSidebar } = useLayout();
	const { currentRouteItem } = useRoutes();
	const { user } = useAuth();
	const { mode, setMode } = useColorScheme();
	const navigate = useNavigate();

	const [open, setOpen] = React.useState(false);

	const handleOpenChange = React.useCallback(
		(event: React.SyntheticEvent | null, isOpen: boolean) => {
			setOpen(isOpen);
		},
		[]
	);

	return (
		<>
			<Box
				sx={{
					position: 'fixed',
					zIndex: 1201,
					top: 0,
					left: 0,
					width: '100vw',
					height: '100vh',
					opacity: 'var(--SideNavigation-slideIn, 0)',
					backgroundColor: 'var(--ballen-palette-background-backdrop)',
					transition: 'opacity 0.4s',
					transform: {
						xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',
						lg: 'translateX(-100%)',
					},
				}}
				onClick={() => closeSidebar()}
			/>
			<Sheet
				color="neutral"
				sx={(theme) => ({
					'--Sheet-background': `var(${
						theme.palette.mode === 'dark'
							? '--ballen-palette-common-black'
							: '--ballen-palette-common-black'
					}, #F3F1F6)`,
					backgroundColor: 'var(--Sheet-background)',
					position: {
						xs: 'fixed',
						md: 'sticky',
					},
					transform: {
						xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
						md: 'none',
					},
					transition: 'transform 0.4s',
					zIndex: 1300,
					height: '100dvh',
					width: 'var(--Sidebar-width)',
					top: 0,
					flexShrink: 0,
					display: 'flex',
					flexDirection: 'column',
					alignItems: hasSidebar ? 'center' : 'flex-start',
					gap: 1,
					borderRight: '1px solid',
					borderColor: 'divider',
					overflow: 'auto',
				})}
			>
				<GlobalStyles
					styles={{
						':root': {
							'--Sidebar-width': hasSidebar ? '68px' : '292px',
						},
					}}
				/>
				<Stack justifyContent="center" alignItems="center" width="100%" py={4}>
					<Link component={RouterLink} to="/">
						<img
							width={150}
							src="https://ballen-studios.s3.amazonaws.com/ballen-logo.png"
							alt="Ballen logo"
						/>
					</Link>
				</Stack>
				<List
					size="sm"
					sx={{
						'--ListItem-radius': '6px',

						width: hasSidebar ? undefined : '100%',
					}}
				>
					{navigation
						.filter((item) => {
							if (!item.permissionSets) return true;
							return item.permissionSets.some((permissionSet) =>
								permissionSet.every((v) => !!user?.permissions?.[v])
							);
						})
						.map(({ path, icon, name }, i) => {
							const item = (
								<ListItem
									key={i}
									sx={{
										paddingInlineStart: 0,
										paddingInlineEnd: 0,
										paddingBlockStart: 0,
										paddingBlockEnd: 0,
									}}
								>
									<ListItemButton
										color="primary"
										selected={currentRouteItem?.path === path}
										variant={
											currentRouteItem?.path === path ? 'solid' : 'plain'
										}
										onClick={() => toggleSidebar()}
										component={RouterLink}
										to={path!}
										sx={{
											display: 'flex',
											justifyContent: 'center',
											minInlineSize: 'none',
											marginInlineEnd: 'none',
											paddingBlock: 0,
											marginInline: 0,
											marginBlock: 0,
											paddingInlineStart: 0,
											paddingInlineEnd: 0,
										}}
									>
										<>
											{!hasSidebar && (
												<ListItemContent
													sx={{
														py: 1.5,
														px: 2,
														minHeight: 39,
														display: 'flex',
														alignItems: 'center',
													}}
												>
													<Typography
														level="body-md"
														textColor="text.tertiary"
													>
														{name}
													</Typography>
												</ListItemContent>
											)}
										</>
									</ListItemButton>
								</ListItem>
							);

							return hasSidebar ? (
								<Tooltip title={name} placement="right" key={i}>
									{item}
								</Tooltip>
							) : (
								item
							);
						})}
				</List>
				<List
					size="sm"
					sx={{
						p: 0,
						mt: 'auto',
						flexGrow: 0,
						'--ListItem-radius': '8px',
						'--List-gap': '8px',
						width: hasSidebar ? undefined : '100%',
					}}
				>
					<ListItem
						sx={{
							paddingInlineStart: 0,
							paddingInlineEnd: 0,
							paddingBlockStart: 0,
							paddingBlockEnd: 0,
						}}
					></ListItem>

					<ListItem
						sx={{
							paddingInlineStart: 0,
							paddingInlineEnd: 0,
							paddingBlockStart: 0,
							paddingBlockEnd: 0,
							px: 1.625,
						}}
					>
						<ListItemButton
							color="primary"
							sx={{
								display: 'flex',
								justifyContent: 'center',
								minInlineSize: 'none',
								marginInlineEnd: 'none',
								paddingBlock: 0,
								marginInline: 0,

								marginBlock: 0,
								paddingInlineStart: 0,
								paddingInlineEnd: 0,
							}}
							onClick={(event) => {
								if (mode === 'light') {
									setMode('dark');
								} else {
									setMode('light');
								}
							}}
						>
							<ListItemDecorator
								sx={{
									width: 39,
									height: 39,
									fontSize: '1.125rem',
									margin: 0,
									justifyContent: 'center',
								}}
							>
								{mode === 'light' ? (
									<FontAwesomeIcon icon={['fas', 'moon']} />
								) : (
									<FontAwesomeIcon icon={['fas', 'sun-bright']} />
								)}
							</ListItemDecorator>
							{!hasSidebar && (
								<ListItemContent>
									<Typography
										textColor="text.tertiary"
										level="title-sm"
										fontWeight="500"
									>
										Toggle {mode === 'light' ? 'dark' : 'light'} theme
									</Typography>
								</ListItemContent>
							)}
						</ListItemButton>
					</ListItem>
				</List>

				{user && (
					<Box sx={{ width: '100%' }}>
						<List size="sm" sx={{ p: 0, flex: 0, width: '100%' }}>
							<ListItem sx={{ p: 0 }}>
								<ListItemButton
									color="primary"
									onClick={() => setOpen((prev) => !prev)}
									sx={{
										display: 'flex',
										minInlineSize: 'none',
										marginInlineEnd: 'none',
										paddingBlock: 0,
										marginInline: 0,
										marginBlock: 0,
										paddingInlineStart: 0,
										paddingInlineEnd: 0,
										px: 1.625,
										pb: 1,
										justifyContent: 'flex-start',
									}}
								>
									<Dropdown onOpenChange={handleOpenChange}>
										<MenuButton
											slots={{ root: IconButton }}
											slotProps={{
												root: {
													variant: 'plain',
													color: 'primary',
												},
											}}
											sx={{ border: 'none', width: '100%' }}
										>
											<ListItemDecorator
												sx={{
													width: 39,
													height: 39,
													margin: 0,
													justifyContent: 'center',
												}}
											>
												<Avatar
													variant="outlined"
													size="sm"
													src={getUserAvatar(user)}
												/>
											</ListItemDecorator>
											{!hasSidebar && (
												<Stack alignItems="start" sx={{ flex: '1 0 auto' }}>
													<Typography
														level="title-md"
														textColor="text.tertiary"
														sx={{
															pl: 1,
															whiteSpace: ' nowrap',
															overflow: 'hidden',
															textOverflow: 'ellipsis',
															maxWidth: '200px',
														}}
													>
														{user.firstName} {user.lastName}
													</Typography>
													<Typography
														textColor="text.tertiary"
														level="body-xs"
														sx={{ pl: 1 }}
													>
														{user?.permissions?.admin
															? 'Administrator'
															: 'User'}
													</Typography>
												</Stack>
											)}
											{!hasSidebar && (
												<FontAwesomeIcon
													icon={[
														'fas',
														open ? 'chevron-up' : 'chevron-down',
													]}
												/>
											)}
										</MenuButton>

										<Menu
											sx={{
												zIndex: 100000,
												width: 'clamp(125px, 200px, 100%)',
											}}
										>
											<MenuItem onClick={() => navigate(`/settings/account`)}>
												<Typography
													startDecorator={
														<FontAwesomeIcon icon={['fad', 'home']} />
													}
												>
													Account
												</Typography>
											</MenuItem>

											<MenuItem onClick={() => navigate('/logout')}>
												<Typography
													startDecorator={
														<FontAwesomeIcon
															icon={['fad', 'sign-out']}
														/>
													}
												>
													Logout
												</Typography>
											</MenuItem>
										</Menu>
									</Dropdown>
								</ListItemButton>
							</ListItem>
						</List>
					</Box>
				)}
			</Sheet>
		</>
	);
};

export default Navigation;
