import { css } from '@emotion/react';
import { Theme } from '@mui/joy';

const globalStyles = (theme: Theme) => css`
	@import url('https://use.typekit.net/puw0rqg.css');
	*,
	*::before,
	*::after {
		box-sizing: border-box;
	}
	input[type='color'] {
		appearance: auto;
		inline-size: 50px;
		block-size: 48px;
		cursor: default;
		box-sizing: border-box;
		padding: 0px;
		background-color: transparent;
		color: transparent;
		border-width: 0px !important;
		padding: 1px 2px;
	}
	input[type='color']::-moz-color-swatch {
		border: none;
	}

	input[type='color']::-webkit-color-swatch {
		border: none;
	}

	html,
	body {
		height: 100% !important;
		-webkit-overflow-scrolling: touch;
		overflow-x: hidden !important;
	}

	body {
		overflow: unset;
	}

	html,
	body,
	#root {
		height: 100%;
	}

	div[role='group'][tabindex] {
		height: 100%;
	}

	button {
		outline: none;
		border: none;
		font-family: inherit;
	}

	a {
		text-decoration: none;
	}

	body {
		margin: 0;
		-webkit-text-size-adjust: 100%;
		font-family: ${theme.typography['body-md'].fontFamily};
	}

	//.wrap-children {
	//	display: flex;
	//	flex-wrap: wrap;
	//}
	//
	//.next-is-wrapped,
	//.next-is-wrapped ~ * {
	//	// [flex-grow vs flex](https://github.com/angular/material/issues/2632)
	//	flex: auto;
	//}
`;

export default globalStyles;
