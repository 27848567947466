import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Breadcrumbs, Container, Link, Stack, Typography, useTheme } from '@mui/joy';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useLayout, useRoutes } from '~/utils/contexts';
import LowerBar from '../LowerBar';

// @ts-ignore
interface IPageHeader {
	$settitle: any; //(HTMLSpanElement | null) => void; doesn't work?!
	$setsearch: any;
	$setactions: any;
	$setHeaderImage: any;
	hasHeaderImage?: boolean;
	hasCustomTitle?: boolean;
}

const DesktopHeader: React.FC<IPageHeader> = (props) => {
	const theme = useTheme();
	const { routeStack, currentRouteItem } = useRoutes();
	const { type, header } = currentRouteItem ?? {};
	const { hasActions } = useLayout();

	const title = (
		<Box
			position="relative"
			ref={(ref) => props.$settitle(ref)}
			sx={{
				flexGrow: 1,
				marginRight: 'auto',
				width: '100%',

				img: {
					position: 'absolute',
					width: '200px',
					right: { xs: -10, md: -45 },
					top: -50,
				},
			}}
		>
			{props.hasCustomTitle ? null : (
				<Typography
					level={type === 'default' ? 'h3' : type === 'detailed' ? 'h2' : 'title-md'}
					component="h1"
					textColor={
						currentRouteItem?.header?.color === 'light'
							? theme.vars.palette.neutral['100']
							: currentRouteItem?.header?.color === 'dark'
							  ? theme.vars.palette.neutral['900']
							  : currentRouteItem?.type === 'detailed'
							    ? theme.vars.palette.neutral['100']
							    : 'text.primary'
					}
					sx={{
						display: currentRouteItem?.header?.title?.isHidden ? 'none' : 'flex',
					}}
				>
					{currentRouteItem?.header?.title?.name
						? currentRouteItem?.header?.title?.name
						: currentRouteItem?.crumb}
				</Typography>
			)}
			<img src="https://ballen-studios.s3.amazonaws.com/flock.png" alt="Bat flock" />
		</Box>
	);

	const search = (
		<Box
			sx={{
				display: 'flex',
				flexGrow: 1,
				justifyContent: hasActions ? 'center' : 'flex-end',
			}}
			ref={(ref) => props.$setsearch(ref)}
		/>
	);

	const actions = (
		<Box
			ref={(ref) => props.$setactions(ref)}
			sx={{
				display: 'flex',
				flexDirection: 'row',

				flexGrow: hasActions ? 1 : 0,
				justifyContent: 'flex-end',
				marginLeft: 'auto',
			}}
		/>
	);

	const emptyBreadcrumbs =
		currentRouteItem?.path === '/' && routeStack?.length === 1 && routeStack?.[0]?.path === '/';

	return (
		<Styles>
			<Container disableGutters maxWidth={currentRouteItem?.header?.container ?? 'xxl'}>
				<Box
					component="header"
					className="Header"
					{...props}
					sx={[
						{
							display: header?.isHidden ? 'none' : 'flex',
							flexDirection: 'column',
							backgroundColor: 'background.body',
							top: 0,
							zIndex: 99,
							background: 'transparent',
							minHeight:
								type === 'detailed' || props.hasHeaderImage ? 350 : undefined,
						},
					]}
				>
					<Box
						ref={(ref) => props.$setHeaderImage(ref)}
						sx={(theme) => ({
							backgroundColor: theme.vars.palette.secondary['700'],
							position: 'absolute',
							top: 0,
							left: 0,
							right: 0,
							bottom: 0,
							height: '100%',
							width: '100%',
							zIndex: '-1',
						})}
						style={{
							display: type === 'detailed' || props.hasHeaderImage ? 'block' : 'none',
						}}
					/>

					{(type === 'default' || type === 'detailed') && !emptyBreadcrumbs && (
						<Breadcrumbs
							size="sm"
							separator={false}
							aria-label="breadcrumbs"
							sx={(theme) => ({
								pl: 0,
								color:
									currentRouteItem?.header?.breadcrumbs?.color === 'light'
										? theme.vars.palette.neutral[100]
										: currentRouteItem?.header?.breadcrumbs?.color === 'dark'
										  ? theme.vars.palette.neutral[900]
										  : currentRouteItem?.header?.color === 'light'
										    ? theme.vars.palette.neutral[100]
										    : currentRouteItem?.header?.color === 'dark'
										      ? theme.vars.palette.neutral[900]
										      : type === 'detailed'
										        ? theme.vars.palette.neutral[100]
										        : undefined,
								a: {
									color:
										currentRouteItem?.header?.breadcrumbs?.color === 'light'
											? theme.vars.palette.neutral[100]
											: currentRouteItem?.header?.breadcrumbs?.color ===
											    'dark'
											  ? theme.vars.palette.neutral[900]
											  : currentRouteItem?.header?.color === 'light'
											    ? theme.vars.palette.neutral[100]
											    : currentRouteItem?.header?.color === 'dark'
											      ? theme.vars.palette.neutral[900]
											      : type === 'detailed'
											        ? theme.vars.palette.neutral[100]
											        : undefined,
								},
							})}
							className="breadcrumbs"
						>
							{routeStack?.map((route, index) => {
								if (!route.crumb) return null;
								if (index === routeStack.length - 1) {
									return (
										<Typography
											key={index}
											color="primary"
											sx={(theme) => ({
												color:
													currentRouteItem?.header?.breadcrumbs?.color ===
													'light'
														? theme.vars.palette.primary[300]
														: currentRouteItem?.header?.breadcrumbs
																	?.color === 'dark'
														  ? theme.vars.palette.primary[700]
														  : currentRouteItem?.header?.color ===
														      'light'
														    ? theme.vars.palette.primary[300]
														    : currentRouteItem?.header?.color ===
														        'dark'
														      ? theme.vars.palette.primary[700]
														      : type === 'detailed'
														        ? theme.vars.palette.primary[300]
														        : undefined,
											})}
											fontWeight={500}
											fontSize={12}
										>
											{route.crumb}
										</Typography>
									);
								}

								return (
									<Typography
										key={index}
										endDecorator={
											<Box sx={(theme) => ({ color: 'text.primary' })}>
												<FontAwesomeIcon
													icon={['fad', 'slash-forward']}
													size="xs"
												/>
											</Box>
										}
									>
										<Link
											key={index}
											component={RouterLink}
											to={route.path!}
											underline="hover"
											color="neutral"
											fontSize={12}
											fontWeight={500}
										>
											{route.crumb}
										</Link>
									</Typography>
								);
							})}
						</Breadcrumbs>
					)}

					<Stack position="relative">
						{title}
						{search}
						{actions}
					</Stack>
				</Box>
			</Container>
		</Styles>
	);
};

export default DesktopHeader;

const Styles = styled.div`
	.breadcrumbs li:nth-child(1) {
		margin-right: 0px;
	}

	.breadcrumbs li:nth-child(2) {
		display: none;
	}
`;
