import { Box } from '@mui/joy';
import React from 'react';

const LowerBar: React.FC = (props) => {
	return (
		<>
			<svg
				style={{ position: 'absolute' }}
				version="1.1"
				id="Layer_1"
				xmlns="http://www.w3.org/2000/svg"
				x="0px"
				y="0px"
				viewBox="0 0 1172 52.7"
			>
				<path
					d="M1172,18.7c0,1.8-0.5,2-2.1,1.3c-3.1-1.5-6.2-1.4-9.4-0.1c-0.9,0.4-1.8,0.7-2.8,0.7c-10.1,0.1-20.1,0-30.2,0
	c-29.7,0.1-59.4,0.3-89.1,0.4c-11.2,0-22.4,0-33.6,0c-8.3,0-16.6,0.2-24.8,0.4c-7.4,0.2-14.8,0.4-22.2,0.9
	c-4.6,0.3-9.1,0.3-13.7-0.2c-2.6-0.2-5.2,0.4-7.8,0.4c-4.8,0.1-9.7,0-14.5,0c-10,0.1-20,0.2-30,0.4c-9.2,0.1-18.5,0.3-27.7,0.4
	c-7.2,0.1-14.3,0.2-21.4,0.4c-5.7,0.1-11.5,0.4-17.2,0.5c-7.9,0.1-15.8-0.1-23.7-0.1c-12,0.1-24,0.2-36,0.4
	c-9.5,0.1-18.9,0.3-28.3,0.4c-9.1,0.1-18.3,0.3-27.4,0.4c-3.5,0.1-6.9,0.3-10.3,0.4c-1.9,0.1-3.7,0-5.6,0c-0.8,0-1.3-0.3-1.2-1.2
	c0.2-1.8-0.7-2.1-2.2-2.1c-19.3,0.2-38.5,0.3-57.8,0.4c-2.3,0-4.6,0.1-6.9,0c-3.4-0.2-6.5,0.9-9.3,2.8c-1.3,0.9-2.7,1.3-4.3,1.3
	c-15.7,0-31.3,0-47,0c-17.3,0-34.7,0-52,0c-1.6,0-3.3,0.2-4.6-1.2c-0.2-0.2-0.7-0.1-1-0.1c-3.1,0.2-6.3,0.4-9.4,0.4
	c-3.8,0.1-7.5,0-11.3,0c-7.9,0.1-15.7,0.2-23.6,0.4c-5.8,0.1-11.7,0.2-17.5,0.4c-5.8,0.1-11.7,0.3-17.5,0.4c-3.3,0.1-6.7,0.3-10,0.3
	c-0.3,0-0.8-0.6-0.9-1.1c-0.4-1.5-0.7-3.1-0.9-4.6c-0.1-0.5,0.3-1.2,0.5-1.7c0.4-0.8,0.7-1.3-0.2-2c-0.4-0.3-0.4-1.2-0.7-1.7
	c-0.6-1-1.3-2-2-3.1c-0.4,0.4-1,0.8-1,1.2c-0.1,1.5,0.1,3,0,4.6c-0.1,2.1-0.3,4.2-0.7,6.2c-0.3,1.5-0.9,1.6-2,0.7
	c-1.3-1.1-4.3-1-5.7,0.1c-0.6,0.5-1.5,0.8-2.2,0.8c-5.9,0.1-11.7,0-17.5,0c-4.7,0.1-9.3,0.2-14,0.4c-0.4,0-1,0.1-1.3,0.4
	c-1,1.2-2.3,1.1-3.6,1.1c-9.3,0-18.6,0-27.9,0c-10.8,0-21.6,0.1-32.5,0c-1.1,0-2.2-0.6-3.4-0.9c-0.5-0.1-1-0.2-1.5-0.2
	c-5.4,0.1-10.8,0.2-16.1,0.4c-2.8,0.1-5.6,0.6-8.5,0.7c-1.8,0.1-3.6-0.2-5.5-0.3c-1.7,0-3.4,0.1-5.1,0.2c-2,0.1-4.1,0.1-6.1,0.1
	c-7.6,0.1-15.1,0.2-22.7,0.4c-5.7,0.1-11.5,0.3-17.2,0.4c-4.7,0.1-9.4,0.3-14.1,0.4c-9,0.2-18,0.2-26.9,0.4
	c-6.3,0.1-12.6,0.2-18.9,0.4c-3.8,0.1-7.5,0.3-11.2,0.5c-1,0-2-0.1-3,0c-7.5,0.1-14.9,0.3-22.4,0.4c-3.3,0.1-6.7,0.2-10,0.3
	c-0.3,0-0.8-0.5-0.9-0.9c-0.4-1.6-0.7-3.2-0.9-4.8c-0.1-0.5,0.2-1.2,0.5-1.7c0.5-0.9,0.6-1.5-0.4-2.1c-0.3-0.2-0.7-0.9-0.6-1.3
	c0.4-1.8-1-2.9-2-3.3c-1.6-0.7-3.3-1-4.8-2.2c-1.5-1.2-2.9,0.1-4.4,0.1c-1.1,0-2.3,0-3.4,0c-0.2,0-0.3,0-0.5,0c-2.2,0-4.5,0-6.8,0.1
	c-0.3,0-0.7,0.3-0.9,0.6c-0.5,0.9-0.9,2-1.6,2.8c-0.3,0.4-1,0.7-1.5,0.9c-1.9,0.5-3.9,0.9-5.9,1.4c-0.4,0.1-0.9,0.2-1.3,0.2
	c-0.7,0-1.5-0.1-2.1,0.1c-1.7,0.7-3.1,0.8-4.6-0.6c-0.9-0.8-2.8,0.5-2.8,1.8c0,0.8,0.1,1.6,0.2,2.4c0.5,2.5,0,3.8-2.2,4.9
	c-0.5,0.2-1.1,0.5-1.4,0.9c-1.2,1.7-2.8,2-4.7,2c-3.8-0.1-7.5-0.1-11.3,0c-6,0.1-12,0-18,0.4c-3,0.2-6.1,1.1-8.8,2.3
	c-3.8,1.7-7.3,4-10.8,6.2c-1.7,1.1-2.8,2.7-3.1,4.9c-0.5,5.1-3.7,6.8-8,4.2C0.5,51.3,0.3,51.2,0,51V0h1171.9
	C1172,6.2,1172,12.4,1172,18.7z"
				/>
			</svg>
		</>
	);
};

export default LowerBar;
