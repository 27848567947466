import { CssVarsThemeOptions, extendTheme, PaletteRange } from '@mui/joy/styles';
import { Theme } from '@mui/joy/styles/types/theme';
import { Components, StyleOverrides } from '@mui/joy/styles/components';
import { AccordionOwnerState, AccordionSlot } from '@mui/joy/Accordion/AccordionProps';
import { DrawerProps } from '@mui/joy/Drawer/DrawerProps';
import React from 'react';
import { Chip, IconButton } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// This part could be declared in your theme file
declare module '@mui/joy/Drawer' {
	interface DrawerPropsSizeOverrides {
		xl: true;
	}
}

// You can put this to any file that's included in your tsconfig
declare module '@mui/joy/styles' {
	interface TypographySystemOverrides {
		'title-xs': true;
		subtitle: true;
	}
}

declare module '@mui/joy/IconButton' {
	interface IconButtonPropsSizeOverrides {
		xl: true;
	}
}

declare module '@mui/joy/styles' {
	interface BreakpointOverrides {
		xxl: true;
	}
}

declare module '@mui/joy/styles' {
	interface ColorPalettePropOverrides {
		secondary: true;
	}

	interface Palette {
		secondary: PaletteRange;
	}
}

const FONT_FAMILY_DISPLAY = ['podium-sharp-variable', 'sans-serif'].join(', ');
const FONT_FAMILY_BODY = ['American Typewriter'].join(', ');
export const theme = extendTheme({
	cssVarPrefix: 'ballen',
	components: {
		JoyDialogTitle: {
			styleOverrides: {
				root: ({ ownerState, theme }) => ({
					color: theme.vars.palette.text.primary,
				}),
			},
		},
		JoyDialogContent: {
			styleOverrides: {
				root: () => ({
					[theme.breakpoints.up('xs')]: {
						gap: theme.spacing(1),
					},
					[theme.breakpoints.up('md')]: {
						gap: theme.spacing(2),
					},
				}),
			},
		},
		JoySwitch: {
			styleOverrides: {
				root: ({ ownerState, theme }) => ({}),
			},
		},
		JoyDrawer: {
			styleOverrides: {
				root: ({ ownerState, theme }: any) => ({
					...(ownerState.size === 'xl' &&
						(ownerState.anchor === 'top' || ownerState.anchor === 'bottom') && {
							'--Drawer-verticalSize': 'clamp(450px, 90%, 100%)',
						}),

					...(ownerState.size === 'xl' &&
						(ownerState.anchor === 'left' || ownerState.anchor === 'right') && {
							'--Drawer-horizontalSize': 'clamp(500px, 90%, 100%)',
						}),

					...(ownerState.size === 'md' && {
						'--Drawer-horizontalSize': 'clamp(500px, 30%, 100%)',
					}),
				}),
			},
		},
		JoyCard: {
			styleOverrides: {
				root: {
					border: 'none',
				},
			},
		},
		JoyIconButton: {
			styleOverrides: {
				root: ({ ownerState, theme }) => ({
					...(ownerState.variant === 'plain' && {
						color: 'var(--ballen-palette-text-icon, #8C8683)',
					}),
				}),
			},
		},
		JoyAutocomplete: {
			styleOverrides: {
				root: ({ ownerState, theme }) => ({
					...(ownerState.variant === 'outlined' && {
						backgroundColor: 'transparent',
					}),
				}),
			},
		},
		JoyInput: {
			defaultProps: {
				variant: 'outlined',
				color: 'neutral',
			},
			styleOverrides: {
				root: ({ ownerState, theme }) => ({
					padding: '8px 12px',
					borderRadius: theme.vars.radius.sm,
					boxShadow: theme.shadow.xs,

					...(ownerState.variant === 'soft' && {
						border: 'none',
					}),
					...(ownerState.variant === 'outlined' && {
						backgroundColor: 'transparent',
					}),
				}),
			},
		},
		JoyFormControl: {
			styleOverrides: {
				root: ({ ownerState, theme }) => ({
					'&.Mui-error': {
						'--FormHelperText-color': theme.vars.palette.danger['600'],
					},
				}),
			},
		},
		JoySelect: {
			defaultProps: {
				variant: 'outlined',
				color: 'neutral',
			},
			styleOverrides: {
				root: ({ ownerState, theme }) => ({
					padding: '12px 12px',
					borderRadius: theme.vars.radius.sm,
					boxShadow: theme.shadow.xs,

					...(ownerState.variant === 'soft' && {
						border: 'none',
					}),
					...(ownerState.variant === 'outlined' && {
						backgroundColor: 'transparent',
					}),
				}),
			},
		},
		JoyAvatar: {
			styleOverrides: {
				root: ({ ownerState, theme }) => ({
					...(ownerState.variant === 'solid' &&
						ownerState.color === 'secondary' && {
							backgroundColor: theme.vars.palette.secondary['700'],
						}),
				}),
			},
		},
		JoyListSubheader: {
			styleOverrides: {
				root: ({ ownerState, theme }) => ({
					fontSize: '1rem',
					fontWeight: 500,
					fontFamily: FONT_FAMILY_BODY,
					letterSpacing: 0,
					textTransform: 'none',
					color: 'var(--ballen-palette-neutral-800, #262230)',
				}),
			},
		},
		JoySvgIcon: {
			styleOverrides: {
				root: ({ ownerState, theme }) => ({
					fontSize: '1rem',
					...(ownerState.size === 'sm' && {
						width: theme.spacing(2),
						height: theme.spacing(2),
					}),
				}),
			},
		},
		JoyButton: {
			styleOverrides: {
				root: ({ ownerState, theme }) => ({
					padding: '4px 16px 4px 16px',
					borderRadius: theme.vars.radius.xl,
					textTransform: 'uppercase',
				}),
			},
		},
		JoyFormLabel: {
			styleOverrides: {
				root: ({ ownerState, theme }) => ({
					color: theme.vars.palette.text.primary,
					fontSize: theme.vars.fontSize.md,
				}),
			},
		},
		JoyListItemButton: {
			styleOverrides: {
				root: ({ ownerState, theme }) => ({
					borderRadius: '0px',
				}),
			},
		},
		JoyTabs: {
			styleOverrides: {
				root: ({ ownerState, theme }) => ({
					backgroundColor: 'var(--ballen-palette-background-body, #262230)',
					'& .MuiTabPanel-root': {
						paddingLeft: 0,
						paddingRight: 0,
					},
				}),
			},
		},
	},

	typography: {
		h1: {
			fontWeight: 700,
			fontFamily: FONT_FAMILY_DISPLAY,
			textTransform: 'uppercase',
			fontVariationSettings: "'wdth' 100 , 'wght' 700",
			fontSize: '3rem',
		},
		h2: {
			fontWeight: 700,
			fontFamily: FONT_FAMILY_DISPLAY,
			textTransform: 'uppercase',
			fontVariationSettings: "'wdth' 100 , 'wght' 700",
			fontSize: '2.2rem',
		},
		h3: {
			fontWeight: 600,
			fontFamily: FONT_FAMILY_DISPLAY,
			textTransform: 'uppercase',
			fontVariationSettings: "'wdth' 130 , 'wght' 700",
			letterSpacing: '0.1rem',
		},
		h4: {
			fontWeight: 600,
			fontFamily: FONT_FAMILY_BODY,
			textTransform: 'uppercase',

			fontSize: '1.7rem',
		},
		'title-lg': {
			fontWeight: 700,
			fontVariationSettings: "'wdth' 130 , 'wght' 700",

			fontFamily: FONT_FAMILY_DISPLAY,
		},
		'title-md': {
			fontWeight: 700,
			fontVariationSettings: "'wdth' 130 , 'wght' 700",

			fontFamily: FONT_FAMILY_DISPLAY,
		},
		'title-sm': {
			fontWeight: 600,
			fontVariationSettings: "'wdth' 130 , 'wght' 600",

			fontFamily: FONT_FAMILY_DISPLAY,
		},
		'title-xs': {
			fontVariationSettings: "'wdth' 130 , 'wght' 500",

			fontSize: '0.75rem',
			fontWeight: 500,
			color: 'var(--ballen-palette-neutral-600, #6D01D2)',
		},
		'body-xs': {
			color: 'var(--ballen-palette-text-secondary, #464443)',
			fontWeight: 600,
		},
		subtitle: {
			fontSize: '0.75rem',
			fontWeight: 600,
			fontFamily: FONT_FAMILY_DISPLAY,
			textTransform: 'uppercase',
			letterSpacing: '0.1rem',
			color: 'var(--ballen-palette-neutral-800, #262230)',
		},
	},
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 900,
			lg: 1200,
			xl: 1500,
			xxl: 1800,
		},
	},
	fontFamily: {
		display: FONT_FAMILY_DISPLAY,
		body: FONT_FAMILY_BODY,
	},
	colorSchemes: {
		light: {
			shadowRing: '0px 1px 0px 0px rgba(0 0 0 / 0.1)',
			shadowChannel: '12 12 12',

			palette: {
				divider: 'var(--ballen-palette-neutral-400, #646B74)',
				text: {
					primary: 'var(--ballen-palette-neutral-900, #242323)',
					secondary: 'var(--ballen-palette-neutral-700, #464443)',
					tertiary: 'var(--ballen-palette-neutral-100, #FFFFFF)',
					icon: 'var(--ballen-palette-neutral-400, #8C8683)',
				},
				background: {
					body: 'var(--ballen-palette-neutral-50, #EBE9F0)',
					surface: 'var(--ballen-palette-neutral-100, #FFFFFF)',
				},

				primary: {
					50: '#F5E3E3',
					100: '#F0B3B3',
					200: '#F28787',
					300: '#EA6362',
					400: '#EB3232',
					500: '#E71F1E',
					600: '#C21716',
					700: '#A31717',
					800: '#760F0F',
					900: '#3D0807',
					softBg: 'var(--ballen-palette-primary-100, #E9D6FF)',
					softColor: 'var(--ballen-palette-primary-500, #E71F1E)',
					softHoverColor: 'var(--ballen-palette-primary-500, #E71F1E)',
					plainColor: 'var(--ballen-palette-primary-700, #A31717)',
					outlinedColor: 'var(--ballen-palette-primary-500, ###E71F1E)',
					plainHoverBg: 'var(--ballen-palette-primary-100, #E71F1E)',
					softHoverBg: 'var(--ballen-palette-primary-200, #F28787)',
					softActiveColor: 'var(--ballen-palette-primary-600, #C21716)',
					softActiveBg: 'var(--ballen-palette-primary-300, #F28787)',
					solidActiveBg: 'var(--ballen-palette-primary-500, #E71F1E)',
				},
				secondary: {
					50: '#F2E1F2',
					100: '#DDBCE0',
					200: '#BA7EBF',
					300: '#903E94',
					400: '#823E88',
					500: '#5F0966',
					600: '#54015B',
					700: '#46004C',
					800: '#36003B',
					900: '#240028',

					solidBg: 'var(--ballen-palette-secondary-400, #823E88)',
					solidActiveBg: 'var(--ballen-palette-secondary-500, #5F0966)',

					outlinedBorder: 'var(--ballen-palette-secondary-500, #5F0966)',
					outlinedColor: 'var(--ballen-palette-secondary-700, #46004C)',
					outlinedActiveBg: 'var(--ballen-palette-secondary-100, #DDBCE0)',

					softColor: 'var(--ballen-palette-secondary-300, #903E94)',
					softHoverColor: 'var(--ballen-palette-secondary-300, #903E94)',
					softActiveColor: 'var(--ballen-palette-secondary-400, #54015B)',

					softBg: 'var(--ballen-palette-secondary-50, #F2E1F2)',
					softHoverBg: 'var(--ballen-palette-secondary-100, #BA7EBF)',
					softActiveBg: 'var(--ballen-palette-secondary-200, #BA7EBF)',

					plainColor: 'var(--ballen-palette-secondary-700, #46004C)',
					plainActiveBg: 'var(--ballen-palette-secondary-100, #DDBCE0)',
				},
				neutral: {
					50: '#f9f6f6',
					100: '#F1EAEC',
					200: '#D9D0C9',
					300: '#D3CCC1',
					400: '#AFA9A2',
					500: '#8C8683',
					600: '#696563',
					700: '#464443',
					800: '#242323',
					900: '#242323',
					softBg: 'var(--ballen-palette-neutral-200, #D9D0C9)',
					outlinedActiveBorder: 'var(--ballen-palette-neutral-300, #DFD8E6)',
					softColor: 'var(--ballen-palette-neutral-700, #464443)',
					softHoverColor: 'var(--ballen-palette-neutral-600, #776B85)',
					softHoverBg: 'var(--ballen-palette-neutral-300, #DFD8E6)',
					solidBorder: 'var(--ballen-palette-neutral-600, #A095AD)',
					softActiveColor: 'var(--ballen-palette-neutral-700, #5A4F69)',
					softActiveBg: 'var(--ballen-palette-neutral-400, #EBE9F0)',
					outlinedBorder: 'var(--ballen-palette-neutral-300, #A095AD)',
					solidColor: 'var(--ballen-palette-neutral-700, #F3F1F6)',
					solidBg: 'var(--ballen-palette-neutral-400, #D9D0C9)',
					solidHoverBg: 'var(--ballen-palette-neutral-600, #776B85)',
					solidActiveBg: 'var(--ballen-palette-neutral-400, #C3BACF)',
					plainColor: 'var(--ballen-palette-neutral-700, #464443)',
					plainHoverColor: 'var(--ballen-palette-neutral-600, #776B85)',
				},
				common: {
					white: '#FFFFFF',
					black: '#000000',
				},
				success: {
					50: '#F6FEF6',
					100: '#E3FBE3',
					200: '#C7F7C7',
					300: '#A1E8A1',
					400: '#51BC51',
					500: '#1F7A1F',
					600: '#136C13',
					700: '#0A470A',
					800: '#042F04',
					900: '#021D02',

					softColor: 'var(--ballen-palette-success-800, #0D633A)',
					softActiveColor: 'var(--ballen-palette-success-900, #04361F)',
					softBg: 'var(--ballen-palette-success-50, #DFFFF0)',
					softHoverBg: 'var(--ballen-palette-success-100, #B8FCDC)',
					softActiveBg: 'var(--ballen-palette-success-200, #92F6C8)',

					solidColor: 'var(--ballen-palette-success-900, #04361F)',
					solidActiveColor: 'var(--ballen-palette-success-900, #04361F)',
					solidBg: 'var(--ballen-palette-success-400, #30D98A)',
					solidHoverBg: 'var(--ballen-palette-success-600, #23B570)',
					solidActiveBg: 'var(--ballen-palette-success-700, #138D55)',
				},
				danger: {
					50: '#FEF6F6',
					100: '#F1C3BD',
					200: '#E4867E',
					300: '#D5453F',
					400: '#C60000',
					500: '#AF0002',
					600: '#900007',
					700: '#68000C',
					800: '#35000B',
					900: '#030001',

					softColor: 'var(--ballen-palette-danger-600, #C12B4E)',
					softActiveColor: 'var(--ballen-palette-danger-700, #941E3B)',
					softBg: 'var(--ballen-palette-danger-100, #F1C3BD)',
					softHoverBg: 'var(--ballen-palette-danger-100, #FBC1CE)',
					softActiveBg: 'var(--ballen-palette-danger-200, #F99EB3)',
					plainColor: 'var(--ballen-palette-danger-700, #941E3B)',
					solidColor: 'var(--ballen-palette-danger-100, #F1C3BD)',
					solidActiveColor: 'var(--ballen-palette-danger-100, #FBC1CE)',
					solidBg: 'var(--ballen-palette-danger-500, #ED3862)',
					solidHoverBg: 'var(--ballen-palette-danger-600, #C12B4E)',
					solidActiveBg: 'var(--ballen-palette-danger-700, #941E3B)',
				},

				warning: {
					50: '#FEFAF6',
					100: '#FDF0E1',
					200: '#FCE1C2',
					300: '#F3C896',
					400: '#EA9A3E',
					500: '#9A5B13',
					600: '#72430D',
					700: '#492B08',
					800: '#2E1B05',
					900: '#1D1002',

					softColor: 'var(--ballen-palette-warning-700, #C12B4E)',
					softActiveColor: 'var(--ballen-palette-warning-700, #941E3B)',
					softBg: 'var(--ballen-palette-warning-100, #FEE4EA)',
					softHoverBg: 'var(--ballen-palette-warning-100, #FBC1CE)',
					softActiveBg: 'var(--ballen-palette-warning-200, #F99EB3)',

					solidColor: 'var(--ballen-palette-warning-50, #FEE4EA)',
					solidActiveColor: 'var(--ballen-palette-warning-100, #FBC1CE)',
					solidBg: 'var(--ballen-palette-warning-500, #ED3862)',
					solidHoverBg: 'var(--ballen-palette-warning-600, #C12B4E)',
					solidActiveBg: 'var(--ballen-palette-warning-700, #941E3B)',
				},
			},
		},
		dark: {
			palette: {
				text: {
					tertiary: 'var(--ballen-palette-neutral-100, #464443)',
				},

				background: {
					body: 'var(--ballen-palette-neutral-800, #EBE9F0)',
					surface: 'var(--ballen-palette-neutral-900, #FFFFFF)',
				},
				primary: {
					50: '#3D0807',
					100: '#760F0F',
					200: '#A31717',
					300: '#C21716',
					400: '#E71F1E',
					500: '#EB3232',
					600: '#EA6362',
					700: '#F28787',
					800: '#F0B3B3',
					900: '#F5E3E3',
					softBg: 'var(--ballen-palette-primary-100, #E9D6FF)',
					softColor: 'var(--ballen-palette-primary-500, #E71F1E)',
					softHoverColor: 'var(--ballen-palette-primary-500, #E71F1E)',
					plainActiveBg: 'var(--ballen-palette-primary-500, #E71F1E)',
					plainColor: 'var(--ballen-palette-primary-900, #A31717)',
					plainHoverBg: 'var(--ballen-palette-primary-400, #E71F1E)',
					softHoverBg: 'var(--ballen-palette-primary-200, #F28787)',
					softActiveColor: 'var(--ballen-palette-primary-600, #C21716)',
					softActiveBg: 'var(--ballen-palette-primary-200, #F28787)',
					solidActiveBg: 'var(--ballen-palette-primary-500, #E71F1E)',
				},
				secondary: {
					50: '#F2E1F2',
					100: '#DDBCE0',
					200: '#BA7EBF',
					300: '#903E94',
					400: '#823E88',
					500: '#5F0966',
					600: '#54015B',
					700: '#46004C',
					800: '#36003B',
					900: '#240028',

					solidBg: 'var(--ballen-palette-secondary-400, #823E88)',
					solidActiveBg: 'var(--ballen-palette-secondary-500, #5F0966)',

					outlinedBorder: 'var(--ballen-palette-secondary-500, #5F0966)',
					outlinedColor: 'var(--ballen-palette-secondary-700, #46004C)',
					outlinedActiveBg: 'var(--ballen-palette-secondary-100, #DDBCE0)',

					softColor: 'var(--ballen-palette-secondary-300, #903E94)',
					softHoverColor: 'var(--ballen-palette-secondary-300, #903E94)',
					softActiveColor: 'var(--ballen-palette-secondary-400, #54015B)',

					softBg: 'var(--ballen-palette-secondary-50, #F2E1F2)',
					softHoverBg: 'var(--ballen-palette-secondary-100, #BA7EBF)',
					softActiveBg: 'var(--ballen-palette-secondary-200, #BA7EBF)',

					plainColor: 'var(--ballen-palette-secondary-700, #46004C)',
					plainActiveBg: 'var(--ballen-palette-secondary-100, #DDBCE0)',
				},
				neutral: {
					50: '#FBFCFE',
					100: '#F0F4F8',
					200: '#DDE7EE',
					300: '#CDD7E1',
					400: '#9FA6AD',
					500: '#636B74',
					600: '#555E68',
					700: '#32383E',
					800: '#171A1C',
					900: '#0B0D0E',

					softBg: 'var(--ballen-palette-neutral-200, #EBE9F0)',
					outlinedActiveBorder: 'var(--ballen-palette-neutral-300, #DFD8E6)',
					softColor: 'var(--ballen-palette-neutral-700, #464443)',
					softHoverColor: 'var(--ballen-palette-neutral-600, #776B85)',
					softHoverBg: 'var(--ballen-palette-neutral-300, #DFD8E6)',
					softActiveColor: 'var(--ballen-palette-neutral-700, #5A4F69)',
					softActiveBg: 'var(--ballen-palette-neutral-400, #EBE9F0)',
					outlinedBorder: 'var(--ballen-palette-neutral-300, #A095AD)',
					solidColor: 'var(--ballen-palette-neutral-100, #F3F1F6)',
					solidBg: 'var(--ballen-palette-neutral-500, #A095AD)',
					solidHoverBg: 'var(--ballen-palette-neutral-600, #776B85)',
					solidActiveBg: 'var(--ballen-palette-neutral-400, #C3BACF)',
					plainColor: 'var(--ballen-palette-neutral-700, #464443)',
					plainHoverColor: 'var(--ballen-palette-neutral-600, #776B85)',
				},
				common: {
					white: '#FFFFFF',
					black: '#000000',
				},
				success: {
					50: '#DFFFF0',
					100: '#B8FCDC',
					200: '#92F6C8',
					300: '#70F0B2',
					400: '#4DE69F',
					500: '#30D98A',
					600: '#23B570',
					700: '#138D55',
					800: '#0D633A',
					900: '#04361F',

					softColor: 'var(--ballen-palette-success-800, #0D633A)',
					softActiveColor: 'var(--ballen-palette-success-900, #04361F)',
					softBg: 'var(--ballen-palette-success-50, #DFFFF0)',
					softHoverBg: 'var(--ballen-palette-success-100, #B8FCDC)',
					softActiveBg: 'var(--ballen-palette-success-200, #92F6C8)',

					solidColor: 'var(--ballen-palette-success-900, #04361F)',
					solidActiveColor: 'var(--ballen-palette-success-900, #04361F)',
					solidBg: 'var(--ballen-palette-success-500, #30D98A)',
					solidHoverBg: 'var(--ballen-palette-success-600, #23B570)',
					solidActiveBg: 'var(--ballen-palette-success-700, #138D55)',
				},
				danger: {
					50: '#FEE4EA',
					100: '#FBC1CE',
					200: '#F99EB3',
					300: '#F57B98',
					400: '#F1597D',
					500: '#ED3862',
					600: '#C12B4E',
					700: '#941E3B',
					800: '#661427',
					900: '#300810',

					softColor: 'var(--ballen-palette-danger-600, #C12B4E)',
					softActiveColor: 'var(--ballen-palette-danger-700, #941E3B)',
					softBg: 'var(--ballen-palette-danger-50, #FEE4EA)',
					softHoverBg: 'var(--ballen-palette-danger-100, #FBC1CE)',
					softActiveBg: 'var(--ballen-palette-danger-200, #F99EB3)',

					solidColor: 'var(--ballen-palette-danger-50, #FEE4EA)',
					solidActiveColor: 'var(--ballen-palette-danger-100, #FBC1CE)',
					solidBg: 'var(--ballen-palette-danger-500, #ED3862)',
					solidHoverBg: 'var(--ballen-palette-danger-600, #C12B4E)',
					solidActiveBg: 'var(--ballen-palette-danger-700, #941E3B)',
				},
			},
		},
	},
} as CssVarsThemeOptions & {
	components?: Components<Theme> & {
		JoyDrawer?: {
			defaultProps?: Partial<DrawerProps>;
			styleOverrides?: StyleOverrides<AccordionSlot, AccordionOwnerState, Theme>;
		};
	};
});
