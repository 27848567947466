import { lazy } from 'react';
import ConversionLayout from '~/components/Layout/ConversionLayout';
import Layout from '~/components/Layout';
import type { PreRoute } from '~/models/route';

// auth
const Offline = lazy(() => import('../pages/offline'));
const Private = lazy(() => import('../components/Private'));
const Protected = lazy(() => import('../components/Protected'));
const Login = lazy(() => import('../pages/login'));
const LoginGoogle = lazy(() => import('../pages/login_google'));
const Logout = lazy(() => import('../pages/logout'));
const VerifyEmail = lazy(() => import('../pages/verify_email'));
const ResetPassword = lazy(() => import('../pages/reset_password'));
const ConfirmPassword = lazy(() => import('../pages/confirm_password'));
const ChangeEmail = lazy(() => import('../pages/change_email'));
// const VerifyEmail = lazy(() => import('../pages/verify_email'));
// const Register = lazy(() => import('../pages/register'));
// const MagicLink = lazy(() => import('../pages/magic_link'));

// other
const Home = lazy(() => import('../pages/index'));
const Reference = lazy(() => import('../pages/reference'));

// settings
const Account = lazy(() => import('../pages/settings/account'));
const BlacklistedTopics = lazy(() => import('../pages/settings/blacklisted-topics'));
const Searches = lazy(() => import('../pages/settings/searches'));
const BlacklistedTopicResults = lazy(() => import('../pages/settings/blacklisted-topic-results'));
const Sources = lazy(() => import('../pages/settings/sources'));
const Source = lazy(() => import('../pages/settings/source'));
const Users = lazy(() => import('../pages/settings/users'));
const User = lazy(() => import('../pages/settings/user'));
const SettingsOverview = lazy(() => import('../pages/settings/settings'));

const Invite = lazy(() => import('../pages/invite'));
const BillingTest = lazy(() => import('../pages/change-plan'));
const Projects = lazy(() => import('../pages/projects'));
const AiTraining = lazy(() => import('../pages/ai-training'));
const AiTrain = lazy(() => import('../pages/ai-train'));
const AiTrainerIssues = lazy(() => import('../pages/ai-trainer_issues'));
const Project = lazy(() => import('../pages/project'));
const Topics = lazy(() => import('../pages/topics'));
const SearchResult = lazy(() => import('../pages/topic'));
const Creators = lazy(() => import('../pages/creators'));
const Creator = lazy(() => import('../pages/creator'));
const Concierge = lazy(() => import('../pages/concierge'));

const routes: PreRoute[] = [
	{
		path: '/reference',
		element: <Reference />,
	},
	// {
	// 	path: '/magic-link',
	// 	element: <MagicLink />,
	// },
	{
		path: '/change-email',
		element: <ChangeEmail />,
	},
	{
		path: '/verify-email',
		element: <VerifyEmail />,
	},
	{
		path: '/invite',
		element: <ConversionLayout />,
		disableHeader: true,
		children: [
			{
				index: true,
				element: <Concierge />,
			},
		],
	},
	{
		path: '/login',
		element: <ConversionLayout />,
		header: {
			isHidden: true,
		},
		children: [
			{
				index: true,
				element: <Login />,
			},
		],
	},

	{
		path: '/login/social',
		element: <ConversionLayout />,
		header: {
			isHidden: true,
		},
		children: [
			{
				index: true,
				element: <LoginGoogle />,
			},
		],
	},
	{
		path: '/logout',
		element: <ConversionLayout />,
		header: {
			isHidden: true,
		},
		children: [
			{
				index: true,
				element: <Logout />,
			},
		],
	},

	{
		path: '/forgot-password',
		element: <ConversionLayout />,
		header: {
			isHidden: true,
		},
		children: [
			{
				index: true,
				element: <ResetPassword />,
			},
		],
	},
	{
		path: '/forgot-password-confirm',
		element: <ConversionLayout />,
		header: {
			isHidden: true,
		},
		children: [
			{
				index: true,
				element: <ConfirmPassword />,
			},
		],
	},

	{
		element: <Private />,
		children: [
			{
				path: '/',
				element: <Layout />,
				children: [
					{
						index: true,
						element: <Home />,
					},
					{
						path: '/admin',
						element: <Protected permissionSets={[['admin']]} />,
						crumb: 'Admin',
						children: [
							{
								index: true,
								element: <div>Admin</div>,
							},
						],
					},

					{
						header: {
							container: 'lg',
						},
						mainContent: {
							container: 'lg',
						},

						path: '/ai-trainer',
						crumb: 'AI Trainer',
						children: [
							{
								header: {
									container: 'lg',
								},
								mainContent: {
									container: 'lg',
								},
								index: true,
								crumb: 'AI Trainer',
								element: <AiTraining />,
							},
							{
								header: {
									container: 'lg',
								},
								mainContent: {
									container: 'lg',
								},
								path: '/ai-trainer/issues',
								crumb: 'AI Trainer Issues',
								element: <AiTrainerIssues />,
							},
							{
								header: {
									container: 'lg',
								},
								mainContent: {
									container: 'lg',
								},
								path: '/ai-trainer/:id',
								crumb: 'Viewing Result',
								element: <AiTrain />,
							},
						],
					},

					{
						crumb: 'Settings',
						path: '/settings',
						children: [
							{
								index: true,
								header: {
									container: 'lg',
								},
								mainContent: {
									container: 'lg',
								},
								element: <SettingsOverview />,
							},
							{
								header: {
									container: 'lg',
								},
								mainContent: {
									container: 'lg',
								},

								path: '/settings/account',
								crumb: 'Account',
								element: <Account />,
							},

							{
								header: {
									container: 'lg',
								},
								mainContent: {
									container: 'lg',
								},

								path: '/settings/sources',
								crumb: 'Sources',
								children: [
									{
										header: {
											container: 'lg',
										},
										mainContent: {
											container: 'lg',
										},
										index: true,
										element: <Sources />,
									},
									{
										header: {
											container: 'lg',
										},
										mainContent: {
											container: 'lg',
										},
										path: '/settings/sources/:id',
										crumb: 'Source',
										element: <Source />,
									},
								],
							},

							{
								header: {
									container: 'lg',
								},
								mainContent: {
									container: 'lg',
								},
								crumb: 'Blacklisted Topics',
								path: '/settings/blacklisted-topics',
								children: [
									{
										header: {
											container: 'lg',
										},
										mainContent: {
											container: 'lg',
										},
										index: true,
										crumb: 'Blacklisted Topics',
										element: <BlacklistedTopics />,
									},
									// {
									// 	header: {
									// 		container: 'lg',
									// 	},
									// 	mainContent: {
									// 		container: 'lg',
									// 	},
									// 	path: '/settings/blacklisted-topics/:topicName',
									// 	crumb: 'Search Results',
									// 	element: <BlacklistedTopicResults />,
									// },
								],
							},
							{
								header: {
									container: 'lg',
								},
								mainContent: {
									container: 'lg',
								},
								crumb: 'Previous Searches',
								path: '/settings/searches',
								children: [
									{
										header: {
											container: 'lg',
										},
										mainContent: {
											container: 'lg',
										},
										index: true,
										crumb: 'Searches',
										element: <Searches />,
									},
								],
							},
							{
								header: {
									container: 'lg',
								},
								mainContent: {
									container: 'lg',
								},

								path: '/settings/users',
								crumb: 'Users',
								children: [
									{
										header: {
											container: 'lg',
										},
										mainContent: {
											container: 'lg',
										},
										index: true,
										crumb: 'Users',
										element: <Users />,
									},
									{
										header: {
											container: 'lg',
										},
										mainContent: {
											container: 'lg',
										},
										path: '/settings/users/:userId',
										crumb: 'User',
										element: <User />,
									},
								],
							},
						],
					},

					{
						path: '/projects',
						crumb: 'Projects',
						children: [
							{
								index: true,
								element: <Projects />,
							},
							{
								crumb: 'Selected Project',
								path: '/projects/:id',
								element: <Project />,
							},
						],
					},
					{
						path: '/articles',
						crumb: 'Articles',

						children: [
							{
								index: true,
								element: <Topics />,
							},
							{
								crumb: 'Viewing Result',
								path: '/articles/:id',
								element: <SearchResult />,
							},
						],
					},
					{
						path: '/creators',
						crumb: 'Creators',

						children: [
							{
								crumb: 'Creators',
								index: true,
								element: <Creators />,
							},
							{
								crumb: 'Creator',
								path: '/creators/:creatorId',
								element: <Creator />,
							},
						],
					},
					{
						path: '/offline',
						element: <Offline />,
						crumb: 'Offline',
					},
					{
						path: '/offline',
						element: <Offline />,
						crumb: 'Offline',
					},
				],
			},
		],
	},
];

interface INavigation {
	name: string;
	path: string;
}

export const navigation: INavigation[] = [
	{
		name: 'Home',
		path: '/',
	},
	{
		name: 'Projects',
		path: '/projects',
	},
	{
		name: 'Articles',
		path: '/articles',
	},

	{
		name: 'Creators',
		path: '/creators',
	},
	{
		name: 'AI Trainer',
		path: '/ai-trainer',
	},
	{
		name: 'Settings',
		path: '/settings',
	},
];

export default routes;
